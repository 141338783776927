import http from '../api/http-common';

class forgotpwService {
	//회원 아이디 검색.
	fetchinpw(id) {
		return http.get(`/forgotpw/${id}`);
	}
	//비밀 번호 찾기 이메일 전송
	sendpw(data) {
		return http.post('/forgotpw/send', data);
	}
	//비밀번호 바꾸기
	changepw(data) {
		return http.put('/forgotpw/change', data);
	}
}

export default new forgotpwService();

// import http from '../api/http-common';

// class findpwService {
// 	findpw(data) {
// 		return http.post('/findpw/send', data);
// 	}
// 	changepw(data) {
// 		return http.patch('/password/change', data);
// 	}
// }

// export default new findpwService();
